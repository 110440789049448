exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-azienda-js": () => import("./../../../src/pages/azienda.js" /* webpackChunkName: "component---src-pages-azienda-js" */),
  "component---src-pages-contatti-js": () => import("./../../../src/pages/contatti.js" /* webpackChunkName: "component---src-pages-contatti-js" */),
  "component---src-pages-eventi-js": () => import("./../../../src/pages/eventi.js" /* webpackChunkName: "component---src-pages-eventi-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-innovazione-js": () => import("./../../../src/pages/innovazione.js" /* webpackChunkName: "component---src-pages-innovazione-js" */),
  "component---src-pages-museo-js": () => import("./../../../src/pages/museo.js" /* webpackChunkName: "component---src-pages-museo-js" */),
  "component---src-pages-storia-js": () => import("./../../../src/pages/storia.js" /* webpackChunkName: "component---src-pages-storia-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

